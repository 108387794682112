/**
 * This is loaded from registration modals
 * It enforces privacy policy acceptance by enabling/disabling the
 * submit button according to the acceptance check-box
 */
class enforcePrivacyPolicy {
    enforce () {
        enforcePrivacyPolicy.disableModalSubmit();
        var pp_accept = $('#pp_accept');
        if (pp_accept) {
            pp_accept.change(
                function () {
                    if (this.checked) {
                        $('button').prop('disabled', false);
                        return;
                    }
                    enforcePrivacyPolicy.disableModalSubmit();
                }
            );
        }
    }
    static disableModalSubmit () {
        $('button').prop('disabled', true);
        // over ride the default 'disabled' css that puts the loading gif over the button
        $('button').css({
            "backgroundImage": "url('')",
            "color": "white"
        });
    }
}

export const privacyPolicy = new enforcePrivacyPolicy();
